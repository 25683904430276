import React from "react";
import "./SolarFooter.css";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import foothead from "../img/bgremovedd.png";
import footimg from "../img/foot-img.png";
import footimg1 from "../img/f-locat.png";
import footimg2 from "../img/f-email.png";
import footimg3 from "../img/f-call.png";

const SolarFooter = () => {
  const devfooter = "</> with ❤️ by ";

  return (
    <footer className="footer">
      <div className="sec-footer">
        <div className="footer-flex">
          <div>
            <img src={foothead} alt="footimg" />
            <p>
           <h4>Get Started With Solar Today</h4> 
Join the renewable energy revolution with Issarson Solar Powers. Contact us today to schedule a consultation and discover the endless benefits of solar energy for your home or business.
            </p>
            
          </div>
          <div>
            <p>Quick Links</p>
            <ul>
              <li>Home</li>
              <li>About Us</li>
              <li>Our Mission </li>
              <li> Features</li>
              <li>Why Issarson Solar?</li>
              <li> Product</li>
              <li>How it Works?</li>
            </ul>
          </div>
          <div>
            <p>Useful Links</p>
            <ul>
              <li>Help Center</li>
              <li>Privacy Policy</li>
              <li>Terms & Condition</li>
              <li>FAQ</li>
              <li> <br/></li>
              <p>Documents</p>
              <li style={{textDecorationLine:"underline"}}>LinkedIn Here</li>
            </ul>
          </div>

          <div>
            <p>Contact Us</p>
            <div className="c-one">
              <div>
                <img src={footimg1} alt="" />
                <p>
                 F 8316A Apna Ghar Enclave, sohna road, Faridabad
                </p>
              </div>
              <div>
                <img src={footimg2} alt="" />
                <p>
                  issarsonpowers@gmail.com <br /> 
                </p>
              
              </div>
              <div>
                <img src={footimg3} alt="" />
                <p>+91 9289403701</p>
              </div>
            </div>
          </div>
        </div>
        {/* {console.log(window.screen.width)}
        {window.screen.width <= 400 && <br />} */}
        <div className="f-footer">
        <div>
          <p>All Rights Reserved @issarson power solutions</p>
            {/* <a
              href="https://junaidrana.vercel.app/"
              target="_blank"
              rel="noreferrer"
            >{devfooter} 
              Junaid Rana<GoVerified style={{ fontSize: "12px", marginLeft: "2px", color:"#03b3ff" }} />
            </a> */}
            </div>
          <div>
            <div>
              <FaFacebookF />
            </div>
            <div>
              <FaInstagram />
            </div>
            <div>
              <FaTwitter />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SolarFooter;
