import React from "react";
import "./Sire.css";
import imgener1 from "../img/imgener1.png";
import imgener2 from "../img/imgener2.png";

const SmartEnergy = () => {
  return (
    <section id="sire">
      <div className="Sire">
        <div>
          <img src={imgener1} alt="img-ener" />
          <img src={imgener2} alt="img-ener" />
        </div>
        <div>
          <h2>Our Mission</h2>
          <h2>ISSARSON SOLAR POWER</h2>
          <p>
          At Issarson Solar Powers, our mission is to empower individuals and businesses with reliable, efficient, and eco-friendly energy solutions. We strive to make solar technology accessible to all, driving positive change for a greener future.
          </p>
        </div>
      </div>
    </section>
  );
};

export default SmartEnergy;
