import React from "react";
import "./Sole.css";
import pic1 from "../img/sole4.png";
import pic2 from "../img/sole1.png";
import pic3 from "../img/sole2.png";
import pic4 from "../img/sole3.png";
const Sole = () => {
  return (
    <section id="working" className="sole">
      <h2 className="about-head">CONNECT TO THE SUN </h2>

      <div className="sole-cards">
        {/* <div className="card-border"> */}
        <div class="pcard">
          <img src={pic1} alt="Avatar" />
          <h2>Request a Quote</h2>
          <p>
          Without obligation, one of our Solar experts will contact you to check your needs
          </p>
        </div>
        {/* </div> */}
        <div class="pcard">
          <img src={pic2} alt="Avatar" />
          <h2>Check compatibility</h2>
          <p>Immediate verification of the technical need for installation</p>
        </div>
        <div class="pcard">
          <img src={pic3} alt="Avatar" />
          <h2>Installation</h2>
          <p>
          A qualified Solar Innovatio installer will place the SIRE in a maximum of 2 hours
          </p>
        </div>
        <div class="pcard">
          <img src={pic4} alt="Avatar" />
          <h2>Enjoy your Sun Power</h2>
          <p>Connect to the APP and enjoy your solar energy</p>
        </div>
      </div>

      <button className="btn">Connect to the sun</button>

    </section>
  );
};

export default Sole;
