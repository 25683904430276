import React from "react";
// import img1 from "../img/img1.png";
// import img2 from "../img/img2.png";
// import img3 from "../img/img3.png";
import "./About.css";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";

const AboutUs = () => {
  return (
    <>
      <section id="aboutme" className="about">
        <h2 className="about-head">About Us</h2>
        <p>
        Welcome to Issarson Powers, your trusted partner in renewable energy solutions in India. With a legacy spanning five years, we have established ourselves as a leading provider of solar panels and batteries, catering to a diverse range of needs across residential, commercial, and industrial sectors.
        <br />
        <p>Founded five years ago, Issarson Powers embarked on a mission to revolutionize the energy landscape in India. Our journey has been fueled by a passion for sustainability and a commitment to delivering high-quality solar products and services.</p>
        </p>
        {/* <div className="about-parent">
          <div className="about-box">
            <img src={img1} alt="img1" />
            <h2>Cristian Testoni</h2>
            <p>
              Ingegnere Meccanico, MBA. Ho ricoperto cariche di consulente e
              progettista in aziende meccaniche. Ho avviato come imprenditore
              svariate iniziative in ambito Automotive e mobilità elettrica.
            </p>
          </div>
          <div className="about-box">
            <img src={img2} alt="img2" />

            <h2>Francesco Gavioli</h2>
            <p>
              Nel corso degli anni ho maturato una profonda conoscenza nella
              progettazione e realizzazione di impianti elettrici e di energia
              rinnovabile. Dal 2020 sono fondatore di Gavioli Impianti, azienda
              all’avanguardia nell’installazione di impianti fotovoltaici
            </p>
          </div>
          <div className="about-box">
            <img src={img3} alt="img3" />

            <h2>Massimo Fabi</h2>
            <p>
              Laurato in Economia e Commercio mi sono occupato per anni di
              Business Development sia in multinazionali che PMI italiane in
              differenti settori
            </p>
          </div>
        </div> */}
        <br />
        <div className="about-quote">
          <RiDoubleQuotesL />
          <p>
          We do not develop photovoltaic panels. The panel is just one component of a more complete engineering solution that includes advanced sensors and integration with home automation systems.
          </p>
          <RiDoubleQuotesR />
        </div>
        {/* <h2 className="about-foot">Cristian Testoni</h2> */}
      </section>
    </>
  );
};

export default AboutUs;
