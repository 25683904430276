import React from "react";
import "./SceSire.css";
import pic1 from "../img/pexels-pix.png";
import pic2 from "../img/pexels-burak.png";
import pic3 from "../img/Vector11.png";
import pic4 from "../img/Vector12.png";

const SceSire = () => {
  return (
    <section id="product">
      <div className="SceSire">
        <h2 className="about-head">CHOOSE YOUR PRODUCT</h2>
        <div className="SceSire-col">
          <div class="pcard">
            <img src={pic1} alt="Avatar" />
            <h2>Solar Power Direct</h2>
            <ul>
              <li>System Plugin</li>
              <li>
              Mechanized polyurea structure and intelligent sensors
              </li>
              <li>1KW High Efficiency Panels</li>
              <li>Inverters and connection to the domestic system</li>
              <li>One control and gesture apps</li>
            </ul>
            <p>RECOMMENDED FOR:</p>
            <div className="flex-image">
              <div class="ellipse-container">
                <img src={pic4} alt="myImage" />
              </div>
              <div class="ellipse-container">
                <img src={pic3} alt="myImage" />
              </div>
            </div>
            <p>Discover the technical features</p>
            <div className="Sec-list">
              <ul>
                <li>Average charging time in summer</li>
                <li>TBD Discharge time at 16A.</li>
                <li>TBD Autonomy from the mains.</li>
                <li>TBD Autonomy in blackout house.</li>
                <li>2 days CO2 emission from 1,022 kg to SOO kg per year</li>
                <li>Average charging time in summer.</li>
              </ul>
            </div>
          </div>

          <div class="pcard">
            <img src={pic2} alt="Avatar" />
            <h2>Solar Power Full</h2>
            <ul>
              <li>
              Mechanized polyurea structure and intelligent sensors
              </li>
              <li>1KW High Efficiency Panels</li>
              <li>Inverters and connection to the domestic system</li>
              <li>One control and gesture apps</li>
            </ul>
            <p>RECOMMENDED FOR:</p>
            <div className="flex-image">
              <div class="ellipse-container tipdowna">
                <img src={pic4} alt="myImage" />
              </div>
              <div class="ellipse-container tipdownb">
                <img src={pic3} alt="myImage" />
              </div>
            </div>
            <p>Title here if they have otherwise remove this</p>
            <div className="Sec-list">
              <ul>
                <li>2 kW system with accumulation.</li>
                <li>Average charging time in summer is 5 hours and 30 minutes.</li>
                <li>Discharge time at 16A is 8 hours.</li>
                <li>Autonomy from the 62Po electricity network.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SceSire;
