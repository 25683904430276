import React from "react";
import Faq from "react-faq-component";
import "./Faq.css";

const FaQs = () => {
  const data = {
    rows: [
      {
        title: "What are the benefits of using solar energy?",
        content:
          "The benefits of solar energy include:Reducing electricity bills,Decreasing carbon footprint,Increasing energy independence,Providing a renewable and sustainable energy source,Potentially increasing property value."
      },
      {
        title: "Is my home suitable for solar panels?",
        content: "Most homes can accommodate solar panels, but factors such as roof condition, orientation, shading, and local climate can affect suitability. A professional solar installer can conduct an assessment to determine if your home is a good candidate for solar panels.",
      },
      {
        title: "How much do solar panels cost?",
        content: "The cost of solar panels varies depending on the size of the system, the type of panels used, installation costs, and any available incentives or rebates. On average, a residential solar system can range from $10,000 to $30,000 before incentives.",
      },
      {
        title: "Are there any financial incentives for installing solar panels?",
        content: "Yes, many governments and local authorities offer financial incentives for installing solar panels, such as tax credits, rebates, and grants. Additionally, some regions offer feed-in tariffs or net metering, which can provide financial benefits for excess energy produced by your solar system."
      },
      {
        title: "How long do solar panels last?",
        content: "Solar panels typically have a lifespan of 25 to 30 years. However, their efficiency may decrease slightly over time. Most manufacturers offer warranties that guarantee a certain level of performance for 20 to 25 years.",
      },
      {
        title: "What maintenance do solar panels require?",
        content: "Solar panels require minimal maintenance. Regularly cleaning the panels to remove dust, debris, and bird droppings can help maintain efficiency. It is also advisable to have an annual inspection by a professional to ensure all components are functioning correctly.",
      },
      {
        title: "How much electricity can I generate with solar panels?",
        content: "The amount of electricity you can generate depends on several factors, including the size of your solar system, the efficiency of the panels, your location, and the amount of sunlight your area receives. A typical residential solar system can generate between 3,000 and 10,000 kilowatt-hours (kWh) of electricity per year." 
      },
    ],
  };

  const styles = {
    bgColor: "#ececec2a",
    titleTextColor: "#363636",
    // color: #54595F;
    rowTitleColor: "#363636",
    rowContentColor: "#54595F",
    // arrowColor: "red",
  };

  const config = {
    animate: true,
    // arrowIcon: "+",
    // tabFocus: trues
    expandIcon: "+",
    collapseIcon: "-",
  };

  return (
    <section className="faq">
      <h2 className="about-head">Frequently Asked Questions</h2>

      <div className="faq-list">
        <div>
          <Faq data={data} styles={styles} config={config} />
        </div>
      </div>
      
    </section>
  );
};

export default FaQs;
