import React from "react";
import "./Pattern.css";
// import pattern1 from "../img/bg-pattern1.png";
import Group1 from "../img/Group-1.png";
import Group2 from "../img/Group-2.png";
import Group from "../img/Group.png";
import Vector from "../img/Vector.png";
import Vector1 from "../img/Vector-1.png";
import Vector2 from "../img/Vector-2.png";

const Pattern = () => {
  return (
    <section id="features">
      <div className="pattern">
        <h2>Connected, Smart, Resilient</h2>
        <div className="pattern-box">
          <div className="box-equal">
            <div>
              <img src={Group1} alt="Group1" />
              <div>
                <h2>Versatile</h2>
                <p>
                It can be installed on any balcony, even inside the condominium. It does not require authorizations or masonry work. Also useful as a sun shield.
                </p>
              </div>
            </div>
            <div>
              <img src={Group2} alt="Group2" />
              <div>
                <h2>Independent & Green</h2>
                <p>
                from non-renewable sources is no longer a utopia. IssarSon provides access to green energy sources even to those who do not have a roof of their own.
                </p>
              </div>
            </div>
            <div>
              <img src={Group} alt="Group" />
              <div>
                <h2>Automated</h2>
                <p>
                Fully automatic and retractable, IssarSon adjusts the amount of sun in your home as needed. A perfect combination of sun and shade. Its sensors adjust the exposure according to the weather conditions.
                </p>
              </div>
            </div>
          </div>

          <div className="box-equal">
            <div>
              <img src={Vector1} alt="vector1" />
              <div>
                <h2>Connected</h2>
                <p>
                The system connects to Alexa, Google, Wi-Fi 2.0 appliances. Through the app, you can access energy production data and receive notifications and suggestions for the optimal use of self-generated energy.


                </p>
              </div>
            </div>
            <div>
              <img src={Vector2} alt="Vector2" />
              <div>
                <h2>Efficient</h2>
                <p>
                Fully automatic and retractable, IssarSon adjusts the amount of sun in your home as needed. A perfect combination of sun and shade. Its sensors adjust the exposure according to the weather conditions.


                </p>
              </div>
            </div>
            <div>
              <img src={Vector} alt="Vector" />
              <div>
                <h2>Resistant</h2>
                <p>
                The system is made with a protective, waterproof, insulating coating that is resistant to aggressive weather phenomena, abrasion, impacts and scratches.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pattern;
