import React from "react";
import pic1 from "../img/Group-22.png";
import pic2 from "../img/Group-33.png";
import pic3 from "../img/Group44.png";
import pic4 from "../img/Group11.png";
import pic5 from "../img/Vector12.png";
import pic6 from "../img/Vector13.png";
import pic7 from "../img/Vector14.png";

import "./PercheSire.css";

const PercheSire = () => {
  return (
    <section id="whyus" className="perche">
      <h2 className="about-head">Why IssarSon?</h2>
      <div>
        <div class="pcard">
          <img src={pic1} alt="Avatar" />

          <h2>Energy saving</h2>
          <p>
          Save from a minimum of 60% on your bill to energy independence also thanks to solar shading that allows a reduction of up to 95% of UV rays and therefore less use of the air conditioner.
          </p>
        </div>
        <div class="pcard">
          <img src={pic2} alt="Avatar" />

          <h2>E-mobility</h2>
          <p>
          The only solution that makes using an electric car truly economical. The efficiency of the panels and the storage batteries guarantee up to 29,000 kilometers per year with zero emissions.
          </p>
        </div>
        <div class="pcard">
          <img src={pic3} alt="Avatar" />

          <h2>Energy Community</h2>
          <p>
          From the concept of condominium to the concept of energy community: Si.Re. it is the system that makes it possible to create a Renewable Energy Community without purchasing electricity from the grid.
          </p>
        </div>
      </div>
      <div className="perche-foot">
        <div class="pcard">
          <img src={pic4} alt="Avatar" />
          <h2>110%</h2>
          <p>Superbonus</p>
        </div>
        <div class="pcard">
          <img src={pic5} alt="Avatar" />
          <h2>65%</h2>
          <p>Home Automation Deduction</p>
        </div>
        <div class="pcard">
          <img src={pic6} alt="Avatar" />
          <p>Custom design</p>
        </div>
        <div class="pcard">
          <img src={pic7} alt="Avatar" />
          <p>Tax advice on bonuses</p>
        </div>
      </div>
    </section>
  );
};

export default PercheSire;
